import { URL } from "@/api/dashBoard.js";
import formatNumber from "@/plugins/formatNumber.js";
import formatDate from "@/plugins/formatDate.js";
import { mapGetters } from "vuex";

import {
  Chart,
  LineElement,
  BarElement,
  BarController,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  LineElement,
  BarElement,
  BarController,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

export default {
  data() {
    return {
      total_invoice_in_year: 0,
      total_invoice: 0,
      total_sold: 0,
      total_bought: 0,
      myChartFirt: null,
      myChartSecond: null,
      userInfor: {},
      labels: [],
      invoices: [],
      sold: [],
      bought: [],
      selectYear: null,
      arrYear: [],
      message: "",
    };
  },

  async created() {
    this.getYearSelect();
    // await this.getUserMe();
    this.getDetailDashboard();
  },

  methods: {
    async getDetailDashboard() {
      if (this.selectCurrentCompany) {
        const param = {
          year: this.selectYear,
          company_id: this.selectCurrentCompany,
        };

        const result = await this.$request({
          url: URL.DASH_BOARD,
          params: param,
        });

        const res = result.data;
        if (res.code == 200) {
          this.total_invoice_in_year = res.data.total_invoice_in_year;
          this.total_invoice = res.data.total_invoice;
          this.total_sold = res.data.total_sold;
          this.total_bought = res.data.total_bought;
          this.labels = res.data.graph.label;
          this.bought = res.data.graph.bought;
          this.invoices = res.data.graph.invoices;
          this.sold = res.data.graph.sold;
          this.message = res.data.message;
        }
      }
    },

    async handleDataChart() {
      const chartNameFirst = "Biểu đồ hóa đơn năm " + this.selectYear;
      const datasetsFirt = [
        {
          label: "Hóa đơn",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderWidth: 1,
          borderColor: "rgb(54, 162, 235)",
          data: this.invoices,
          fill: true,
        },
      ];
      const configFirt = {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: datasetsFirt,
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: chartNameFirst,
            },
          },
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value;
                  }
                },
                stepSize: 1,
              },
            },
          },
        },
      };

      this.myChartFirt = new Chart(this.$refs.myChartFirt, configFirt);

      const chartNameSecond = "Biểu đồ doanh thu năm " + this.selectYear;
      const datasetsSecond = [
        {
          label: "Mua vào",
          backgroundColor: "#ff638415",
          borderWidth: 2,
          borderColor: "#ff6384",
          data: this.bought,
          tension: 0.4,
          fill: true,
        },
        {
          label: "Bán ra",
          backgroundColor: "#36a2eb15",
          borderWidth: 2,
          borderColor: "#36a2eb",
          data: this.sold,
          tension: 0.4,
          fill: true,
        },
      ];
      const configSecond = {
        type: "line",
        data: {
          labels: this.labels,
          datasets: datasetsSecond,
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: chartNameSecond,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      };

      this.myChartSecond = new Chart(this.$refs.myChartSecond, configSecond);
    },

    getYearSelect() {
      if (this.getTargetCompany) {
        this.arrYear = [];
        const d = new Date();
        let start = this.getTargetCompany.start_year;
        let end = d.getFullYear();
        this.selectYear = end;
        for (let index = start; index <= end; index++) {
          this.arrYear.unshift(index);
        }
      }
    },

    async getUserMe() {
      const result = await this.$request({
        url: URL.GET_USER_INFO,
      });
      const res = result.data;
      if (res.code == 200) {
        this.userInfor = res.data;
      }
    },

    changeYear() {
      this.getDetailDashboard();
    },

    formatNumber,
    formatDate,
  },

  computed: {
    ...mapGetters([
      "getTargetCompany",
      "selectCurrentCompany",
      "getUserIndexRole",
    ]),
  },

  watch: {
    async selectCurrentCompany(newVal) {
      if (newVal == -1) return;
      await this.getYearSelect();
      await this.getDetailDashboard();
    },

    async invoices() {
      if (!this.myChartFirt) {
        await this.handleDataChart();
      } else {
        this.myChartFirt.destroy();
        this.myChartSecond.destroy();
        this.myChartFirt = null;
        this.myChartSecond = null;
        await this.handleDataChart();
      }
    },
  },
};
